@layer base {
  .main {
  padding: var(--spacer-400) 0;
  overflow: hidden;
}

.layout {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  background-color: transparent;
}

.layoutColum {
  margin-top: 0;
  margin-bottom: 0;
}

.pageTitle {
  display: inline-block;
  position: relative;
  margin-bottom: var(--spacer-300);
}

.title {
  font-family: var(--fontHeading);
  font-size: var(--heading);
  font-weight: var(--black);
  line-height: var(--headingLineHeight);
}

.title > p {
  margin-top: 0;
  margin-bottom: 0;
}

.subtitle {
  margin-top: var(--spacer-300);
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.subtitle p {
  margin: 0;
}

.title strong {
  display: inline-block;
  position: relative;
  font-weight: var(--black);
}

.title strong::before,
.pageTitle::before {
  content: "";
  display: block;
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  bottom: 11%;
  left: 0;
  width: 100%;
}

.pageTitle::before {
  height: 8px;
  background-color: var(--color-grey--100);
}

.title strong::before {
  height: 23%;
  background-color: var(--color-purple--500);
}

.button {
  margin-top: var(--spacer-400);
}

.gridWrapper {
  position: relative;
}

.gridWrapper::after,
.gridWrapper::before {
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  left: -40px;
  opacity: 0.3;
  color: var(--color-black);
  font-size: min(20vw, 280px);
  font-weight: var(--black);
  line-height: min(100%, 220px);
  text-shadow:
    rgb(136 80 255) 1px 0 0,
    rgb(136 80 255) 0.5403px 0.8415px 0,
    rgb(136 80 255) -0.4161px 0.9093px 0,
    rgb(136 80 255) -0.99px 0.141px 0,
    rgb(136 80 255) -0.6536px -0.7568px 0,
    rgb(136 80 255) 0.2837px -0.9589px 0,
    rgb(136 80 255) 0.9602px -0.2794px 0;
  text-transform: uppercase;
}

.gridWrapper::before {
  content: attr(data-highlighted-word-one);
  top: -30px;
}

.gridWrapper::after {
  content: attr(data-highlighted-word-two);
  bottom: 0;
  margin-bottom: calc(var(--spacer-400) * 2);
}

.gridWrapper--withNoImage {
  padding: var(--spacer-300) 0;
  text-align: center;
}

.gridWrapper--withNoImage .layoutTextBlock {
  position: relative;
}

.gridWrapper--withNoImage .layoutTextBlock::before {
  content: "";
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: -20px;
  right: -70px;
  width: 140px;
  height: 135px;
  background-image: var(--backgroundImage);
  background-size: cover;
}

.imagesWrapper {
  position: relative;
  margin-top: var(--spacer-400);
}

.figureImagePrimary,
.figureImageSecondary {
  margin: 0;
}

.figureImagePrimary {
  position: relative;
  text-align: right;
}

.figureImagePrimary--withSecondary {
  margin-right: calc(var(--spacer-400) * -1);
}

.imagePrimary,
.imageSecondary {
  border-radius: var(--radius-200);
}

.imagePrimary {
  width: 100%;
  height: auto;
}

.imagePrimary--withSecondary {
  width: 54%;
  min-width: 212px;
  max-width: var(--widthImage);
  height: auto;
}

.imageSecondary {
  width: 100%;
  height: auto;
}

.figureImageSecondary {
  position: absolute;
  top: 15%;
  right: 30%;
  width: 52%;
  min-width: 205px;
  max-width: 379px;
  height: auto;
}

.figureImageSecondary::after {
  /* This is the way we choose to use getAssetsUrl() by passing the url as a var in inline css */
  content: var(--backgroundImage);
  position: absolute;
  top: -50px;
  left: -78px;
  width: 156px;
  height: 68px;
  transform: scale(0.6);
}

@media (--medium-viewport) {
  .pageTitle {
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .title {
    font-size: var(--headingExtra);
    line-height: var(--headingExtraLineHeight);
  }

  .subtitle {
    font-size: var(--editoBody);
    line-height: var(--editoBodyLineHeight);
  }

  .gridWrapper::before,
  .gridWrapper::after {
    left: -120px;
  }
}

@media (--large-viewport) {
  .main {
    padding: 0;
  }

  .gridWrapper:not(.gridWrapper--withNoImage) {
    display: grid;
    position: relative;
    grid-template-columns: 2fr 1fr;
    gap: var(--spacer-200);
    padding: 86px 0;
  }

  .gridWrapper::before {
    top: 6px;
  }

  .gridWrapper::after {
    margin-bottom: -54px;
  }

  .gridWrapper--withNoImage {
    padding: calc(var(--spacer-800) * 2) 0;
    text-align: center;
  }

  .gridWrapper--withTwoImage::after {
    margin-bottom: -210px;
  }

  .gridWrapper--withTwoWorld {
    margin-bottom: 54px;
  }

  .gridWrapper--withTwoImage.gridWrapper--withTwoWorld {
    margin-bottom: 208px;
  }

  .layoutTextBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .gridWrapper--withNoImage .layoutTextBlock {
    align-items: center;
  }

  .gridWrapper--withNoImage .layoutTextBlock::before {
    top: -110px;
    right: -150px;
    width: 262px;
    height: 240px;
  }

  .imagesWrapper {
    /* the secondary image must be relative to another parent */
    position: static;
    margin-top: 0;
  }

  .imagePrimary {
    width: auto;
    height: inherit;
    max-height: 556px;
  }

  .figureImagePrimary {
    height: var(--height);
    max-height: 556px;
    margin-right: calc((var(--spacer-400) * 2) * -1);
  }

  .figureImageSecondary {
    position: absolute;
    top: inherit;
    bottom: -100px;
    left: 50%;
    margin: 0;
    transform: translateX(-40%);
  }

  .figureImageSecondary::after {
    transform: initial;
  }
}

@media (--major-viewport) {
  .figureImageSecondary {
    bottom: -70px;
  }
}

/* Light Theme */
[data-theme^="light"].main,
[data-theme^="light"].layout {
  background: var(--color-white);
  color: var(--color-black);
}

[data-theme^="light"] .button {
  background: var(--color-black);
  color: var(--color-white);
}

[data-theme^="light"] .gridWrapper::after,
[data-theme^="light"] .gridWrapper::before {
  opacity: 0.1;
  background: linear-gradient(-8deg, rgb(157 44 255 / 100%) 11%, rgb(0 210 0 / 100%) 100%);
  -webkit-text-stroke: 3px transparent;
  background-clip: text;
  color: white;
  text-shadow: none;
}

/* Dark Theme */
[data-theme^="dark"].main,
[data-theme^="dark"].layout {
  background: var(--color-black);
  color: var(--color-white);
}

[data-theme^="dark"] .button {
  background: var(--color-white);
  color: var(--color-black);
}

/* SVG */
.figureImagePrimary::after {
  content: var(--backgroundImage);
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
}

[data-theme="light"] .figureImagePrimary::after,
[data-theme="dark"] .figureImagePrimary::after {
  top: -70px;
  right: -60px;
  max-width: 163px;
  height: 141px;
}

[data-theme*="essentiel"] .figureImagePrimary::after {
  bottom: calc(var(--spacer-400) * -1);
  left: var(--spacer-150);
  width: 71px;
  height: 71px;
}

[data-theme*="medias"] .figureImagePrimary::after {
  top: -34px;
  left: -128px;
  width: 175px;
  height: 139px;
}

[data-theme*="vcf"] .figureImagePrimary::after,
[data-theme*="shortlist"] .figureImagePrimary::after {
  width: 158px;
  height: 68px;
}

[data-theme*="vcf"] .figureImagePrimary::after {
  top: -10px;
  left: -90px;
}

[data-theme*="shortlist"] .figureImagePrimary::after {
  bottom: 70px;
  left: -110px;
}

[data-theme*="sponsoring"] .figureImagePrimary::after {
  bottom: 0;
  left: -80px;
  width: 160px;
  height: 48px;
}

@media (--large-viewport) {
  [data-theme="light"] .figureImagePrimary::after,
  [data-theme="dark"] .figureImagePrimary::after {
    top: -47px;
    right: -78px;
    max-width: inherit;
  }

  [data-theme*="essentiel"] .figureImagePrimary::after {
    bottom: var(--spacer-200);
  }

  [data-theme*="medias"] .figureImagePrimary::after {
    left: -38px;
  }

  [data-theme*="vcf"] .figureImagePrimary::after {
    top: -25px;
    left: -50px;
  }

  [data-theme*="shortlist"] .figureImagePrimary::after {
    bottom: 0;
    left: -20px;
  }

  [data-theme*="sponsoring"] .figureImagePrimary::after {
    bottom: 20px;
    left: -30px;
  }
}

}